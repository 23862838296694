
.not-found-container {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #50c878cd, #007accbf); /* Vibrant gradient background */
    color: white;
    font-family: 'Arial', sans-serif;
    overflow: hidden;
    position: relative;
  }
  
  .error-code {
    font-size: 10rem;
    margin: 0;
    animation: fade-in 2s ease-in-out;
  }
  
  .error-message {
    font-size: 1.5rem;
    margin-top: -20px;
    animation: fade-in 2.5s ease-in-out;
  }
  
  .animated-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  }
  
  .bubble {
    position: absolute;
    bottom: -50px;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    animation: bubble-rise 8s infinite;
  }
  
  .bubble:nth-child(1) {
    left: 20%;
    animation-duration: 6s;
    animation-delay: 0s;
  }
  
  .bubble:nth-child(2) {
    left: 40%;
    animation-duration: 8s;
    animation-delay: 2s;
  }
  
  .bubble:nth-child(3) {
    left: 60%;
    animation-duration: 7s;
    animation-delay: 4s;
  }
  
  .bubble:nth-child(4) {
    left: 80%;
    animation-duration: 5s;
    animation-delay: 1s;
  }
  
  .bubble:nth-child(5) {
    left: 10%;
    animation-duration: 9s;
    animation-delay: 3s;
  }
  
  /* Animations */
  @keyframes bubble-rise {
    0% {
      transform: translateY(0) scale(1);
    }
    50% {
      transform: translateY(-300px) scale(1.1);
    }
    100% {
      transform: translateY(-600px) scale(0.9);
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  