/* General container styles */
.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background: linear-gradient(135deg, #50c878cd, #007accbf); /* Vibrant gradient background */
    font-family: 'Poppins', sans-serif; /* Stylish Google Font */
    animation: fadeIn 1.5s ease;
    position: relative; /* Important for absolutely positioned elements */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Content section styling */
.content {
    text-align: center;
    margin-bottom: 50px;
    color: #fff;
}

.title {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 15px;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); /* Add subtle text shadow */
}

.description {
    font-size: 1.3rem;
    margin-bottom: 25px;
    color: #fdfdfd;
    line-height: 1.5;
}

/* Countdown Container Styles */
.countdown-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
}

.countdown-item {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    min-width: 80px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
}


.countdown-value {
    font-size: 2.5rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}

.countdown-label {
    font-size: 1rem;
    color: #f1f1f1;
}

/* Hourglass animation */
.hourglass {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    transform-origin: center;
    position: relative;
    animation: flow-hourglass 3s infinite ease-in-out; /* Smooth dynamic animation */
}

.hourglass::before,
.hourglass::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: linear-gradient(to bottom, #FFD700, transparent); /* Simulated sand gradient */
    border-radius: 50%;
    transform-origin: center;
    animation: sand-move 1.5s infinite alternate; /* Alternate sand movement */
}

.hourglass::before {
    top: -20px;
    left: 10px;
    animation-delay: 0s;
}

.hourglass::after {
    bottom: -20px;
    left: 10px;
    animation-delay: 0.75s;
}

@keyframes flow-hourglass {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1.1); /* Slight zoom during flip */
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}

@keyframes sand-move {
    0% {
        transform: translateY(0) scale(1);
    }
    100% {
        transform: translateY(40px) scale(0.8); /* Simulated falling sand */
    }
}

/* Button styling */
.coming-soon-btn {
    background-color: #50c878;
    color: #f4f4f4;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 8px 20px;
    letter-spacing: 0.5px;
    outline: none;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.coming-soon-btn:hover {
    background-color: #007acc;
}

/* Form section styling */
.coming-soon-form {
    text-align: center;
    width: 100%;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Stronger shadow for pop-out effect */
}

.subscribe-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
}

.form-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap; /* Ensure proper layout on smaller screens */
}

.coming-soon-input {
    flex: 1; /* Ensure input takes majority space */
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    width: 100%; /* Adjust width for small screens */
}

.coming-soon-input:focus {
    border-color: #50c878; /* Match green theme */
    box-shadow: 0 0 8px rgba(80, 200, 120, 0.5); /* Glow effect on focus */
}

/* Disclaimer modal */
.disclaimer-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
}

.disclaimer-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    text-align: center;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.5s ease;
}

.disclaimer-content h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

.disclaimer-content p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
}

.close-disclaimer-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #50c878;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.close-disclaimer-btn:hover {
    background-color: #007acc;
}

/* Music Toggle Button */
.music-toggle-btn {
    position:fixed;
    top: 20px; /* Adjust as needed */
    right: 20px;  /* Adjust as needed */
    background-color: rgba(255, 255, 255, 0.8);
    color: #007acc;
    border: none;
    outline: none;
    border-radius: 50%;
    padding: 12px;
    cursor: pointer;
    font-size: 1.5rem;
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.music-toggle-btn:hover {
    background-color: rgba(255, 255, 255, 1);
    transform: scale(1.1);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .title {
        font-size: 2.5rem;
    }

    .description {
        font-size: 1.1rem;
    }

    .form-group {
        flex-direction: column;
        gap: 15px;
    }

    .disclaimer-content {
        padding: 20px;
        font-size: 0.9rem;
    }

    .disclaimer-content h3 {
        font-size: 1.2rem;
    }

    .disclaimer-content p {
        font-size: 0.9rem;
    }

    .close-disclaimer-btn {
        font-size: 0.9rem;
        padding: 8px 16px;
    }

    .countdown-container {
        gap: 10px;
    }

    .countdown-item {
        padding: 15px;
        min-width: 60px;
    }

    .countdown-value {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .disclaimer-content {
        padding: 15px;
    }

    .disclaimer-content h3 {
        font-size: 1rem;
    }

    .disclaimer-content p {
        font-size: 0.8rem;
    }

    .close-disclaimer-btn {
        font-size: 0.8rem;
        padding: 6px 12px;
    }

    .countdown-value {
        font-size: 1.5rem;
    }

    .music-toggle-btn {
        top: 15px;
        right: 15px;
        padding: 10px;
        font-size: 1.3rem;
    }
}
