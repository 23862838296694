
.header {
    background: linear-gradient(260deg, #50c878c2, #50c878c2);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #50c878c2;
    padding: 10px 20px;
    /* position: relative; */
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    box-sizing: border-box;
}

.logo-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
}

/* Header Tabs for Desktop */
.header-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.header-tabs ul {
    display: flex;
    align-items: center;
    gap: 20px;
}

.header-tabs ul li {
    list-style: none;
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
    padding: 0 0 5px 0;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
}

.header-tabs ul li:hover {
    color: #50C878;
}

.header-tabs ul li a {
    text-decoration: none;
    color: #007ACC;
    display: block;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
    transition: all .5s;
}

/* Hide Hamburger Menu Icon (fa-bars) on Large Screens */
.header-tabs ul li i {
    display: none; /* Hidden by default */
}

/* Sidebar Styling */
.sidebar-tabs {
    position: fixed;
    right: -100%;
    height: 30vh;
    width: 250px;
    top: 0;
    /* background: linear-gradient(260deg, #50c878c2, #50c878c2); */
    background-color: white;
    box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    transition: right 0.3s ease-in-out;
    z-index: 999;
}

.sidebar-tabs.active {
    right: 0;
}

.sidebar-tabs ul {
    list-style: none;
    padding: 0;
    width: 100%;
    margin: 10px;
    /* margin-top: 50px; */

}
.sidebar-tabs ul li {
    margin: 15px 0;
    width: 100%;
    text-align: left;
}


.sidebar-tabs ul li a {
    text-decoration: none;
    font-size: 1.2rem;
    text-decoration: none;
    display: block;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
    transition: all .5s;
    color: #007ACC

}

.sidebar-tabs ul li:hover {
    color: #50C878;
}

.sidebar-tabs ul li i {
    font-size: 2rem;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .header {
        flex-direction: row;
        justify-content: space-between;
    }

    .header-tabs ul li a {
        display: none;
        color: #007ACC;
    }

    .header-tabs ul li i {
        display: block; 
        font-size: 1.8rem;
    
    }

    .sidebar-tabs {
        width: 100%;
    }
}