.footer-icons ul li a {
    margin: 0 10px;
    font-size: 1.5rem;
    color: white;
    transition: color 0.2s ease, transform 0.5s ease;
    cursor: pointer;
}

.footer-icons ul li:hover a {
    transform: scale(1.2);
}

.footer-icons ul li:nth-child(1) a:hover {
    color: #0A66C2;
}

/* LinkedIn */
.footer-icons ul li:nth-child(2) a:hover {
    color: #C13584;
}

/* Instagram */
.footer-icons ul li:nth-child(3) a:hover {
    color: #1877F2;
}

/* Facebook */

.footer {
    background: linear-gradient(135deg, #007acc, #50c878);
    color: white;
    padding: 30px 20px;
    text-align: center;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.branding {
    text-align: center;
    margin: 20px 0;
    flex: 1 1 100%;
}

.branding h2 {
    font-size: 24px;
    font-weight: bold;
    color: #FFF;
}

.branding p {
    font-size: 16px;
    font-style: italic;
    color: #6c757d;
}

.footer-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px 0;
    flex: 1 1 100%;
}

.footer-icons ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.telecom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 1.1rem;
    flex: 1 1 100%;
}

.telecom span {
    margin: 5px 0;
}

.telecom span strong {
    color: #FFF;
}

.footer-content p {
    font-size: 0.9rem;
    color: #e0e0e0;
    text-align: center;
    width: 100%;
    margin-top: 20px;
}

.disclaimer {
    padding: 20px;
    border-radius: 5px;
    margin-top: 30px;
}

.disclaimer p {
    font-size: 0.9rem;
    margin: 0;
}


@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        padding: 20px;
    }

    .footer-icons ul {
        justify-content: center;
    }

    .footer-icons ul li {
        font-size: 1.5rem;
    }

    .telecom {
        align-items: center;
        font-size: 1rem;
        padding: 10px;
    }

    .footer-content p {
        font-size: 0.85rem;
        margin-top: 10px;
    }
}